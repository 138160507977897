import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService {

  constructor() { }

  exportExcel(data:any[],fileName:string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      !fileName ? fileName = "file" : null;
      this.saveAsExcelFile(excelBuffer,fileName);
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(
        data,
        fileName+'_' + new Date().toLocaleString() + EXCEL_EXTENSION
      );
    });
  }

}
