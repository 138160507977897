import { ColumnTypeInterface } from "./column-type.interface";

export interface ColumnInterface {
  field       : string;
  header        : string;
  isSorted    : boolean;
  isFiltered  : boolean;
  type        : ColumnTypeInterface ;
  actionField? : ActionField []
  buttons?:ButtonsField[];
  width ?     : string
}
export interface ButtonsField{
  isActive : boolean;
  text:string;
}
export enum ActionField {
  EDIT ="edit",
  SWITCH ="switch",
  DELETE ="delete",
  ACCEPT ="accept",
  REJECT ="reject",
  VIEW = "view",
  PAYNOW="paynow",
  BUTTON ="button",
  CREATEMEETING="createMeeting"
}
