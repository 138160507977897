import { BehaviorSubject, Observable } from "rxjs";

export class TutorCancellationService {
  constructor(){}
  private _tutorCancellation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public tutorCancellation: Observable<any> = this._tutorCancellation.asObservable();
  setNewTutorCancellation(message: any): void {
    this._tutorCancellation.next(message);
}
}
